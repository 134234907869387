import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { FilterOptionsState } from '@mui/material/useAutocomplete/useAutocomplete';
// import throttle from 'lodash/throttle';
import { debounce } from 'lodash';
import UncontrolledInputField from './UncontrolledInputField';

type PropsType<OptionType> = {
    label: string,
    helperText?: string,
    defaultOption?: OptionType | OptionType[] | null,
    displayOption?: OptionType | OptionType[] | null,
    optionIdKey: keyof OptionType,
    fetchOptions: (query?: string) => Promise<OptionType[]> | void,
    minQueryLength?: number,
    maxOptions?: number,
    options?: OptionType[],
    getOptionName: (option: OptionType) => string,
    getOptionDescription?: (option: OptionType) => string,
    OptionIcon?: React.ElementType,
    onChange: (v: any) => void,
    filterOptions?: (options: OptionType[], state: FilterOptionsState<OptionType>) => OptionType[],
    fetchOnlyOnce?: boolean,
    variant?: 'standard' | 'filled' | 'outlined'
    size?: 'small' | 'medium',
    disabled?: boolean,
    disableClearable?: boolean,
    multiple?: boolean,
    required?: boolean
};

// Dispatch fetch in fetchOptions and send in selected options as options
// or use api directly to handle options locally (not using options).
export default function AutocompleteField<OptionType>(props: PropsType<OptionType>): JSX.Element {
    const {
        multiple = false,
        label,
        helperText,
        defaultOption = null,
        displayOption,
        optionIdKey,
        fetchOptions,
        minQueryLength,
        maxOptions = 100,
        options = null,
        getOptionName,
        getOptionDescription,
        OptionIcon,
        onChange,
        filterOptions,
        fetchOnlyOnce,
        variant = 'standard',
        size = 'medium',
        disabled = false,
        disableClearable = false,
        required = false
    } = props;

    const [isFetching, setIsFetching] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [shouldFetch, setShouldFetch] = React.useState(true);
    const [value, setValue] = React.useState<OptionType | OptionType[] | null>(displayOption || defaultOption);
    const [query, setQuery] = React.useState('');
    const [localOptions, setLocalOptions] = React.useState<readonly OptionType[]>([]);

    const fetchDebounced = React.useMemo(() => debounce( // Could use throttle instead
        (q: string, callback: (results: readonly OptionType[]) => void) => {
            (async () => {
                const newOptions = await fetchOptions(q);
                if (!options && newOptions) {
                    callback(newOptions);
                }
            })();
        },
        500,
        // { leading: false }
    ), []);

    React.useEffect(() => {
        if (displayOption !== undefined) {
            setValue(displayOption);
        }
    }, [displayOption]);

    React.useEffect(() => {
        // Only set defaultOption initially
        if (value === null && defaultOption !== undefined) {
            setValue(defaultOption);
        }
    }, [defaultOption]);

    React.useEffect(() => {
        let active = true;

        (async () => {
            if (
                open
                && shouldFetch
                && (!minQueryLength || minQueryLength <= query.length)
            ) {
                setIsFetching(true);
                if (fetchOnlyOnce) {
                    setShouldFetch(false);
                    const results = await fetchOptions();
                    if (active) {
                        if (!options && results) {
                            setLocalOptions(results);
                        }
                        setIsFetching(false);
                    }
                } else {
                    fetchDebounced(query, (results: readonly OptionType[]) => {
                        if (active) {
                            if (!options && results) {
                                setLocalOptions(results);
                            }
                            setIsFetching(false);
                        }
                    });
                }
            } else {
                setIsFetching(false);
            }
        })();

        return () => {
            active = false;
        };
    }, [open, value, query, fetchDebounced, minQueryLength, shouldFetch]);

    const defaultFilterOptions = React.useCallback((opts: OptionType[], { inputValue }: { inputValue: string }) => (
        !inputValue ? opts : opts.filter((o) => (
            getOptionName(o).toLowerCase().includes(inputValue.toLowerCase())))
    ), [getOptionName]);

    const filterOptionsLimited = React.useCallback((opts: OptionType[], { inputValue }: { inputValue: string }) => {
        const f = filterOptions || defaultFilterOptions;
        if (maxOptions) {
            return f(opts, { inputValue: inputValue || query, getOptionLabel: getOptionName }).slice(0, maxOptions);
        }
        return f(opts, { inputValue: inputValue || query, getOptionLabel: getOptionName });
    }, [defaultFilterOptions, filterOptions, getOptionName, maxOptions, query]);

    return (
        <Autocomplete
            multiple={multiple}
            filterSelectedOptions={multiple}
            disabled={disabled}
            disableClearable={disableClearable}
            getOptionLabel={getOptionName}
            noOptionsText={
                (!minQueryLength || minQueryLength <= query.length)
                    ? 'Inga resultat, justera din sökning'
                    : `Skriv minst ${minQueryLength} tecken för att söka`
            }
            filterOptions={filterOptionsLimited}
            isOptionEqualToValue={(option, selectedOption) => option[optionIdKey] === selectedOption[optionIdKey]}
            options={
                (!minQueryLength || minQueryLength <= query.length)
                    ? options || localOptions
                    : []
            }
            loading={isFetching}
            loadingText="Laddar…"
            value={value}
            openOnFocus
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            clearText="Rensa"
            onChange={async (event: any, newValue: OptionType | OptionType[] | null) => {
                setValue(newValue);
                onChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setQuery(newInputValue);
            }}
            renderInput={(params) => (
                <UncontrolledInputField
                    {...params}
                    name="query"
                    isSet={Array.isArray(value) ? !!value.length : !!value}
                    label={label}
                    helperText={helperText}
                    fullWidth
                    variant={variant}
                    size={size}
                    required={required}
                />
            )}
            renderOption={(renderProps, option) => {
                const optionName = getOptionName(option);
                const regexp = new RegExp(query.toLowerCase(), 'g');
                const matches = Array.from(optionName.toLowerCase().matchAll(regexp));
                const parts = parse(
                    optionName,
                    matches.map((match: any) => [match.index, match.index + match[0].length]),
                );

                return (
                    <li {...renderProps}>
                        <Grid container alignItems="center">
                            {OptionIcon && (
                                <Grid item>
                                    <Box component={OptionIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                                </Grid>
                            )}
                            <Grid item xs>
                                {parts.map((part: { highlight: boolean, text: string }, index: number) => (
                                    <span
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                                {getOptionDescription && (
                                    <Typography variant="body2" color="text.secondary">
                                        {getOptionDescription(option)}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
