export const defaultMarketFilters = {
    isJob: true,
    isCapacity: true,
    watchlistId: null,
    watchlistName: '',
    pickupLocationQuery: '',
    pickupLocationName: '',
    pickupLocationLat: null,
    pickupLocationLng: null,
    pickupLocationRadius: 100000,
    deliveryLocationQuery: '',
    deliveryLocationName: '',
    deliveryLocationLat: null,
    deliveryLocationLng: null,
    deliveryLocationRadius: 100000,
    firstPickupDate: null,
    lastDeliveryDate: null,
    minGrossWeight: null,
    maxGrossWeight: null,
    minChargeableWeight: null,
    maxChargeableWeight: null,
    minVolume: null,
    maxVolume: null,
    minLoadingMeters: null,
    maxLoadingMeters: null,
    minPalletPlaces: null,
    maxPalletPlaces: null,
    minPallets: null,
    maxPallets: null,
    groupId: null,
    groupName: '',
    hasListPrice: false,
    hasOffer: false,
    takesOffer: false,
    instantNotifications: true,
    dailyNotifications: true,
    userId: null,
    tenantId: null,
    createdBy: null
};

export const marketFilterLimits = {
    minLocationRadius: 10,
    maxLocationRadius: 1000,
    stepLocationRadius: 10,
    minGrossWeight: 0,
    maxGrossWeight: 36000,
    stepGrossWeight: 100,
    minChargeableWeight: 0,
    maxChargeableWeight: 36000,
    stepChargeableWeight: 100,
    minVolume: 0,
    maxVolume: 100,
    stepVolume: 1,
    minLoadingMeters: 0,
    maxLoadingMeters: 25,
    stepLoadingMeters: 1,
    minPalletPlaces: 0,
    maxPalletPlaces: 53,
    stepPalletPlaces: 1,
    minPallets: 0,
    maxPallets: 100,
    stepPallets: 1,
};

export type MarketSettingsType = {
    settingsOpen?: boolean,
    autoFocus?: boolean,
    showPickupPin?: boolean,
    showDeliveryPin?: boolean,
    showDistanceLine?: boolean
};
