import React from 'react';
import {
    Circle,
    GoogleMap,
    Marker
} from '@react-google-maps/api';
import getMarkerIcon from '../../../utils/getMarkerIcon';

type PropsType = {
    pickupLat?: number | null,
    pickupLng?: number | null,
    pickupRadius?: number | null,
    deliveryLat?: number | null,
    deliveryLng?: number | null,
    deliveryRadius?: number | null,
    onPickupDragEnd?: (a: any) => void,
    onDeliveryDragEnd?: (a: any) => void,
    height?: number | string
    minHeight?: number | string
};

const defaultCenter = { lat: 63, lng: 16 };
const defaultZoom = 5;
// const markerZoomLevel = 15;
const mapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false,
    minZoom: 4,
    maxZoom: 20
};

const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: '#FF0000',
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    // radius: 30000,
    zIndex: 1
};

const filterMarkerIcon = getMarkerIcon({ strokeColor: '#555', fillColor: '#FFDDD2', filled: true });

const FilterMap: React.FC<PropsType> = (props: PropsType) => {
    const {
        pickupLat,
        pickupLng,
        pickupRadius,
        deliveryLat,
        deliveryLng,
        deliveryRadius,
        onPickupDragEnd,
        onDeliveryDragEnd,
        height = '100%',
        minHeight = 500
    } = props;

    const [map, setMap] = React.useState<any>(null);

    React.useEffect(() => {
        const bounds = new window.google.maps.LatLngBounds();

        if (pickupLat && pickupLng && pickupRadius) {
            const pickupBounds = (new window.google.maps.Circle({
                radius: pickupRadius,
                center: { lat: pickupLat, lng: pickupLng }
            })).getBounds();
            if (pickupBounds) {
                bounds.extend(pickupBounds.getNorthEast());
                bounds.extend(pickupBounds.getSouthWest());
            }
        }

        if (deliveryLat && deliveryLng && deliveryRadius) {
            const deliveryBounds = (new window.google.maps.Circle({
                radius: deliveryRadius,
                center: { lat: deliveryLat, lng: deliveryLng }
            })).getBounds();
            if (deliveryBounds) {
                bounds.extend(deliveryBounds.getNorthEast());
                bounds.extend(deliveryBounds.getSouthWest());
            }
        }

        if (map && ((pickupLat && pickupLng) || (deliveryLat && deliveryLng))) {
            window.setTimeout(() => map.fitBounds(bounds), 0);
        }
    }, [map, pickupLat, pickupLng, pickupRadius, deliveryLat, deliveryLng, deliveryRadius]);

    return (
        <GoogleMap
            mapContainerStyle={{ height, minHeight, overflow: 'visible' }}
            center={defaultCenter}
            zoom={defaultZoom}
            onLoad={setMap}
            options={mapOptions}
        >
            {pickupLat && pickupLng && pickupRadius && (
                <>
                    <Marker
                        key="pickupMarker"
                        position={{ lat: pickupLat, lng: pickupLng }}
                        label={{ text: 'U', color: '#555', fontWeight: '600' }}
                        icon={filterMarkerIcon}
                        draggable={!!onPickupDragEnd}
                        onDragEnd={onPickupDragEnd}
                    />
                    <Circle
                        center={{ lat: pickupLat, lng: pickupLng }}
                        radius={pickupRadius}
                        options={{
                            ...circleOptions,
                            strokeColor: '#CCAAA0',
                            fillColor: '#FFDDD2',
                            fillOpacity: 0.4
                        }}
                    />
                </>
            )}
            {deliveryLat && deliveryLng && deliveryRadius && (
                <>
                    <Marker
                        key="deliveryMarker"
                        position={{ lat: deliveryLat, lng: deliveryLng }}
                        label={{ text: 'L', color: '#555', fontWeight: '600' }}
                        icon={filterMarkerIcon}
                        draggable={!!onDeliveryDragEnd}
                        onDragEnd={onDeliveryDragEnd}
                    />
                    <Circle
                        center={{ lat: deliveryLat, lng: deliveryLng }}
                        radius={deliveryRadius}
                        options={{
                            ...circleOptions,
                            strokeColor: '#CCAAA0',
                            fillColor: '#FFDDD2',
                            fillOpacity: 0.4
                        }}
                    />
                </>
            )}
        </GoogleMap>
    );
};

export default FilterMap;
