import React from 'react';
import { Grid } from '@mui/material';
import { endOfDay } from 'date-fns';
import { useParams } from 'react-router-dom';

import {
    AutocompleteField,
    NumberField,
    TextField,
    DateTimeField,
    BoolField
} from '../../CommonComponents/Form';

import { SectionDivider } from '../../CommonComponents';

import { fetchGroupsByTenantId, selectGroupsByTenantId } from '../../../redux/actions/groups';
import { fetchUsersByTenantId, selectUsersByTenantId } from '../../../redux/actions/users';
import { useDispatch, useSelector } from '../../../redux/hooks';
import OrderDate from './OrderDate';
import OrderLocation from './OrderLocation';

type PropsType = {
    entity: { [key: string]: any },
    onChange: (e: { [key: string]: any }) => void,
    skipRequiredValidation?: boolean
};

export default function OrderFormFields(props: PropsType) {
    const { onChange, entity, skipRequiredValidation } = props;
    const { id } = useParams();

    const dispatch = useDispatch();
    const groups = useSelector(selectGroupsByTenantId(entity.tenantId));

    const fetchGroups = React.useCallback(() => {
        dispatch(fetchGroupsByTenantId(entity.tenantId));
    }, [dispatch, entity.tenantId]);

    const users = useSelector(selectUsersByTenantId(entity.tenantId));

    const fetchUsers = React.useCallback(() => {
        dispatch(fetchUsersByTenantId(entity.tenantId));
    }, [dispatch, entity.tenantId]);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <SectionDivider label="Upphämtningsdatum" marginTop={0} />
                    <OrderDate
                        partialKey="pickup"
                        partialName="upphämtnings"
                        entity={entity}
                        onChange={onChange}
                        skipRequiredValidation={skipRequiredValidation}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <SectionDivider label="Leveransdatum" marginTop={0} />
                    <OrderDate
                        partialKey="delivery"
                        partialName="leverans"
                        entity={entity}
                        onChange={onChange}
                        skipRequiredValidation={skipRequiredValidation}
                        firstMinDate={entity.firstPickupDate ? entity.firstPickupDate : undefined}
                        lastMinDate={entity.lastPickupDate ? entity.lastPickupDate : undefined}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <SectionDivider label="Upphämtningsplats" />
                    <OrderLocation
                        partialKey="pickup"
                        partialName="upphämtnings"
                        entity={entity}
                        onChange={onChange}
                        skipRequiredValidation={skipRequiredValidation}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <SectionDivider label="Leveranssplats" />
                    <OrderLocation
                        partialKey="delivery"
                        partialName="leverans"
                        entity={entity}
                        onChange={onChange}
                        skipRequiredValidation={skipRequiredValidation}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <SectionDivider label="Gods" />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <NumberField
                                name="grossWeight"
                                label="Totalvikt [kg]"
                                value={entity.grossWeight}
                                onChange={onChange}
                                minValue={0}
                                required={!skipRequiredValidation}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <NumberField
                                name="chargeableWeight"
                                label="FDR-vikt [kg]"
                                value={entity.chargeableWeight}
                                onChange={onChange}
                                minValue={0}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <NumberField
                                name="loadingMeters"
                                label="Flakmeter"
                                value={entity.loadingMeters}
                                onChange={onChange}
                                minValue={0}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <NumberField
                                name="volume"
                                label="Volym [m³]"
                                value={entity.volume}
                                onChange={onChange}
                                minValue={0}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <NumberField
                                name="palletPlaces"
                                label="Pallplatser"
                                value={entity.palletPlaces}
                                onChange={onChange}
                                minValue={0}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <NumberField
                                name="pallets"
                                label="Pallar"
                                value={entity.pallets}
                                onChange={onChange}
                                minValue={0}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <SectionDivider label="Publicering" />
                    <Grid container spacing={2}>
                        {!id && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        name="tenantName"
                                        label="Företag"
                                        value={entity.tenantName}
                                        onChange={onChange}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutocompleteField<{ id: number, name: string, isAdmin: boolean }>
                                        label="Användare"
                                        helperText="Välj en användare för publicering"
                                        optionIdKey="id"
                                        fetchOptions={fetchUsers}
                                        options={users.filter(
                                            (u: { tenantIsAccepted: boolean }) => u.tenantIsAccepted
                                        )}
                                        getOptionName={(u) => u.name}
                                        getOptionDescription={(u) => `${u.isAdmin ? 'Administratör' : 'Användare'}`}
                                        onChange={(u) => onChange({ userId: u ? u.id : '' })}
                                        fetchOnlyOnce
                                        displayOption={entity.name || ''}
                                        required={!skipRequiredValidation}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} lg={6}>
                            <BoolField
                                name="showTenantName"
                                label="Visa ert företagsnamn"
                                value={entity.showTenantName}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <BoolField
                                name="isPublic"
                                label="Publicera publikt"
                                value={entity.isPublic}
                                onChange={({ isPublic }) => onChange({ isPublic, groups: [] })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteField<{ id: number, name: string, ownerTenantName?: string }>
                                label="Publicera i grupp"
                                helperText="Välj grupper eller publicera publikt"
                                optionIdKey="id"
                                fetchOptions={fetchGroups}
                                options={groups.filter(
                                    (o: {
                                        isOwner: boolean;
                                        isAccepted: boolean;
                                        canPublish: boolean;
                                    }) => (
                                        o.isOwner
                                        || (o.isAccepted && o.canPublish))
                                )}
                                getOptionName={(o) => o.name}
                                getOptionDescription={(o) => o.ownerTenantName || ''}
                                onChange={(g) => onChange({ groups: g, isPublic: g.length > 0 ? false : undefined })}
                                fetchOnlyOnce
                                defaultOption={entity.groups || []}
                                displayOption={entity.groups || []}
                                multiple
                                required={!skipRequiredValidation && !entity.isPublic}
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <NumberField
                                name="listPrice"
                                label="Riktpris [SEK]"
                                value={entity.listPrice}
                                onChange={onChange}
                                minValue={0}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <DateTimeField
                                name="expiresAt"
                                label="Anmäl intresse senast"
                                value={entity.expiresAt}
                                onChange={onChange}
                                minDateTime={new Date()}
                                maxDateTime={
                                    entity.lastDeliveryDate || entity.firstDeliveryDate
                                        ? endOfDay(new Date(entity.lastDeliveryDate || entity.firstDeliveryDate))
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="publicDescription"
                                label="Publik beskrivning"
                                value={entity.publicDescription}
                                onChange={onChange}
                                helperText="Visas för alla som kan se uppdraget. Ex: Krav på biltyp, villkor etc."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="privateDescription"
                                label="Privat beskrivning"
                                value={entity.privateDescription}
                                onChange={onChange}
                                helperText="Visas endast för den som tilldelas uppdraget.
                                Ex: Leveransdetaljer som portkod etc."
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
