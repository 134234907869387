import store from '../redux';
import { setMessage } from '../redux/actions/toast';

const messages: { [key: string]: string } = {
    'Not authorized.': 'Du saknar behörighet.',
    'An account with the given email already exists.': 'Ett konto med den angivna e-postadressen finns redan.',
    'You do not have permission to publish to the selected groups.': 'Du saknar behörighet att publicera till den här gruppen.',
    'Cannot delete current user': 'Du kan inte ta bort ditt eget konto',
    'Order can not be publish in inactive date interval.': 'Du kan inte publicera uppdrag i inaktuellt datumintervall.',
    'Company must have atleast one admin to be accepted': 'Företaget måste ha minst en administratör för att kunna accepteras',
    'Company must have atleast one admin': 'Företaget måste ha minst en administratör',
    'Cannot delete tenant with users': 'Kan inte ta bort företag med användare',
    'duplicate key value violates unique constraint "tenants_org_number_key"': 'Orgnumret används redan.'
};
const failureIcon: string = '❌ \u00A0\u00A0\u00A0';
const successIcon: string = '✅ \u00A0\u00A0\u00A0';

// function decodeDbMessage(message) {
//     "update or delete on table \"tenants\" violates foreign key constraint \"groups_owner_tenant_id_fkey\" on table \"groups\""
//     message.split(/.../);
//     'update or delete on table '
//     \"tenants\"
//     'violates foreign key constraint'
//     \"groups_owner_tenant_id_fkey\"
//     'on table'
//     \"groups\""
// }

function translateMessage(message: string) {
    return messages[message] || null;
}

export function formatMessage(data: any, fallbackMessage?: string) {
    if (typeof data === 'string') {
        return data;
    }
    // Error from backend in english
    if (typeof data?.message === 'string' && translateMessage(data.message)) {
        return translateMessage(data.message);
    }
    if (typeof fallbackMessage === 'string') {
        return fallbackMessage;
    }
    return '';
}

export default async function toastMessage(
    data: any,
    fallbackMessage?: string,
    isSuccess?: boolean
) {
    const prefixIcon: string = isSuccess === true ? successIcon : failureIcon;

    const parseData = (): any => {
        return (typeof data === 'string' && isSuccess !== undefined)
            ? prefixIcon + data
            : data;
    };

    const parseFallbackMessage = (): string | undefined => {
        return (fallbackMessage && isSuccess !== undefined)
            ? prefixIcon + fallbackMessage
            : fallbackMessage;
    };

    store.dispatch(setMessage(null));
    window.setTimeout(() => {
        store.dispatch(setMessage(formatMessage(parseData(), parseFallbackMessage())));
    }, 0);
}
