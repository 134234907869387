import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchWatchlistsByUserId,
    selectFetchingWatchlistsByUserId,
    selectWatchlistsByUserId
} from '../../redux/actions/watchlists';
import { WatchlistForUserListType } from '../../types';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';

const columns = [{
    name: 'Namn',
    key: 'name',
    canSearch: true
}, {
    name: 'Direktnotiser',
    key: 'instantNotifications',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Dagliga notiser',
    key: 'dailyNotifications',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

function UserWatchlists(props: FetchPropsType<WatchlistForUserListType[]>) {
    const { entity, isFetching } = props;
    const { id } = useParams() as unknown as { id: number };
    const navigate = useNavigate();

    const onRowClick = React.useCallback((watchlist: { id: number }) => {
        navigate(`/watchlists/${watchlist.id}`);
    }, [navigate]);

    const onAddClick = React.useCallback(() => {
        navigate(`/users/${id}/watchlists/create`);
    }, [navigate, id]);

    return (
        <Table
            name="userWatchlists"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            onAddClick={{ action: onAddClick }}
            emptyText={isFetching ? 'Hämtar bevakningar...' : 'Det finns inga bevakningar.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function UserWatchlistsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<WatchlistForUserListType[]>
            fetchEntity={fetchWatchlistsByUserId(id)}
            selectEntity={selectWatchlistsByUserId(id)}
            selectIsFetching={selectFetchingWatchlistsByUserId(id)}
            Component={UserWatchlists}
            name="bevakningar"
            initialFetch={!hasRendered}
        />
    );
}
