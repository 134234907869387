import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchAllUsers,
    selectAllUsers,
    selectFetchingAllUsers
} from '../../redux/actions/users';

const columns = [{
    name: 'Företag',
    key: 'tenantName',
    canSearch: true,
}, {
    name: 'Namn',
    key: 'name',
    canSearch: true
}, {
    name: 'E-post',
    key: 'email',
    canSearch: true
}, {
    name: 'Mobil',
    key: 'phone',
    canSearch: true
}, {
    name: 'E-post ver.',
    key: 'emailVerified',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Mobil ver.',
    key: 'phoneVerified',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Admin',
    key: 'isAdmin',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Accepterad',
    key: 'tenantIsAccepted',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}, {
    name: 'Senast aktiv',
    key: 'activeAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Users() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const users = useSelector(selectAllUsers());
    const isFetching = useSelector(selectFetchingAllUsers());

    React.useEffect(() => {
        dispatch(fetchAllUsers());
    }, [dispatch]);

    const onRowClick = React.useCallback((user: { id: number }) => {
        navigate(`/users/${user.id}`);
    }, [navigate]);

    return (
        <Container>
            <Header
                title="Användare"
                addButton={{ url: '/users/create' }}
            />

            <Paper padding={0}>
                <Table
                    name="users"
                    columns={columns}
                    data={users}
                    onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar användare...' : 'Det finns inga användare.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                    // batchActions={[{
                    //     name: 'Aktivera',
                    //     // icon?: JSX.Element | undefined;
                    //     action: async (t) => {
                    //         console.log(t);
                    //     }
                    //     // color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
                    //     // variant?: "text" | ... 2 more ... | undefined;
                    //     // getDisabled?: ((a: any[]) => boolean) | undefined;
                    // }]}
                />
            </Paper>
        </Container>
    );
}
