import users from '../slices/users';
import type { RootStateType, ThunkDispatchType } from '../index';

import {
    getUser,
    getUserMeta,
    getUsers, getUsersByTenantId
} from '../../api-admin/users';

export function fetchAllUsers() {
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(users.actions.fetchAll());
            const data = await getUsers();
            dispatch(users.actions.allFetched({ data }));
        } catch (e) {
            console.log('ERROR fetching users, set redux error state and handle in errorBoundary.', e);
            dispatch(users.actions.allFetched({ data: [] }));
        }
    };
}

export function fetchUsersByTenantId(id: number | string) {
    if (!id) {
        throw Error('No id supplied for tenant');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(users.actions.fetchByTenantId({ id }));
            const data = await getUsersByTenantId(id);
            dispatch(users.actions.byTenantIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching users for tenant, set redux error state and handle in errorBoundary.', e);
            dispatch(users.actions.byTenantIdFetched({ id, data: [] }));
        }
    };
}

export function fetchUserById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for user');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(users.actions.fetchById({ id }));
            const data = await getUser(id);
            dispatch(users.actions.byIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching user, set redux error state and handle in errorBoundary.', e);
            dispatch(users.actions.byIdFetched({ id }));
        }
    };
}

export function fetchUserMetaById(id: number | string) {
    if (!id) {
        throw Error('No id supplied for user');
    }
    return async (dispatch: ThunkDispatchType) => {
        try {
            dispatch(users.actions.fetchMetaById({ id }));
            const data = await getUserMeta(id);
            dispatch(users.actions.metaByIdFetched({ id, data }));
        } catch (e) {
            console.log('ERROR fetching user meta, set redux error state and handle in errorBoundary.', e);
            dispatch(users.actions.metaByIdFetched({ id }));
        }
    };
}

export const selectAllUsers = () => (state: RootStateType) => state.users.all;
export const selectFetchingAllUsers = () => (state: RootStateType) => state.users.fetchingAll;

export const selectUsersByTenantId = (id: number | string) => (state: RootStateType) => (
    state.users.byTenantId[id] ?? []
);
export const selectFetchingUsersByTenantId = (id: number | string) => (state: RootStateType) => (
    state.users.fetchingByTenantId[id]
);

export const selectUserById = (id: number | string) => (state: RootStateType) => (
    state.users.byId[id]
);
export const selectFetchingUserById = (id: number | string) => (state: RootStateType) => (
    state.users.fetchingById[id]
);

export const selectUserMetaById = (id: number | string) => (state: RootStateType) => (
    state.users.metaById[id]
);
export const selectFetchingUserMetaById = (id: number | string) => (state: RootStateType) => (
    state.users.fetchingMetaById[id]
);
