import React, { useContext } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Switch
} from '@mui/material';
import FormContext from '../FormContext';

type PropsType = {
    name: string,
    label: string,
    value: boolean,
    variant?: 'switch',
    helperText?: string,
    disabled?: boolean,
    onChange: (a: any) => void
};

const BoolField: React.FC<PropsType> = (props) => {
    const {
        name,
        label,
        value,
        variant,
        helperText = '',
        disabled,
        onChange
    } = props;

    const formContext = useContext(FormContext);
    const isSubmitting = formContext?.isSubmitting;

    const handleChange = React.useCallback((e) => {
        onChange({ [name]: e.target.checked });
    }, [name, onChange]);

    return (
        <FormControl component="fieldset" variant="standard">
            <FormGroup>
                <FormControlLabel
                    label={label}
                    disabled={disabled || isSubmitting}
                    control={
                        variant === 'switch'
                            ? <Switch checked={!!value} name={name} onChange={handleChange} />
                            : <Checkbox checked={!!value} name={name} onChange={handleChange} />
                    }

                />
            </FormGroup>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default BoolField;
