import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';
import { Edit, ExpandMore } from '@mui/icons-material';

import { Block, List } from '../CommonComponents';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { OrderType } from '../../types';
import { fetchOrderById, selectFetchingOrderById, selectOrderById } from '../../redux/actions/orders';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { canEditOrder } from '../../utils/order';
import OrderMap from './OrderMap';
import {
    formatYesNo,
    formatDateTime,
    formatLoadArray,
    formatNumber,
    formatOrderStatus,
    getOrderDeliveryPlace,
    getOrderPickupDateTime,
    getOrderPickupPlace
} from '../../utils/parsing';

function OrderDetails(props: FetchPropsType<OrderType>) {
    const { entity } = props;
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Block>
                    <OrderMap
                        entityType="LL"
                        pickupLat={entity.pickupLat}
                        pickupLng={entity.pickupLng}
                        deliveryLat={entity.deliveryLat}
                        deliveryLng={entity.deliveryLng}
                        height={800}
                    />
                </Block>
            </Grid>

            <Grid item xs={12} lg={6}>
                <Block
                    variant="outlined"
                    title="Grunduppgifter"
                    ActionComponent={(
                        <Button
                            variant="outlined"
                            startIcon={<Edit />}
                            onClick={() => navigate(`/orders/edit/${entity.id}`)}
                            sx={{ ml: 1, mb: 1 }}
                            disabled={(entity === null || !canEditOrder(entity))}
                        >
                            Redigera
                        </Button>
                    )}
                >
                    <List
                        data={[{
                            name: 'Id',
                            value: entity.id
                        }, {
                            name: 'Status',
                            value: formatOrderStatus(entity.status)
                        }, {
                            name: 'Publicerat av',
                            value: entity.tenantName,
                            url: `/tenants/${entity.tenantId}`
                        }, {
                            name: 'Publicerat publikt',
                            value: entity.isPublic,
                            type: entity.isPublic ? 'y/n' : 'hidden'
                        }, {
                            name: `Publicerat i grupp${entity.groupNames.length > 1 ? 'er' : ''}`,
                            value: !entity.isPublic ? entity.groupNames.join(', ') : '',
                            type: entity.isPublic ? 'hidden' : 'raw'
                        }, {
                            name: 'Upphämtningsdatum',
                            value: getOrderPickupDateTime(entity)
                        }, {
                            name: 'Leveransdatum',
                            value: getOrderPickupDateTime(entity)
                        }, {
                            name: 'Upphämtningsplats',
                            value: getOrderPickupPlace(entity)
                        }, {
                            name: 'Leveransplats',
                            value: getOrderDeliveryPlace(entity)
                        }, {
                            name: 'Gods',
                            value: formatLoadArray(entity).join(', '),
                        }, {
                            name: 'Körsträcka',
                            value: entity.drivingDistance
                                ? formatNumber(Math.round(entity.drivingDistance / 1000), 'km')
                                : '-'
                        }, {
                            name: 'Tilldelat',
                            value: !entity.acceptedAt
                                ? 'Nej' : `${formatDateTime(entity.acceptedAt)} av ${entity.acceptedByName || '-'}`,
                            url: entity.acceptedBy ? `/users/${entity.acceptedBy}` : undefined
                        }, {
                            name: 'Bekräftat',
                            value: !entity.startedAt
                                ? 'Nej' : `${formatDateTime(entity.startedAt)} av ${entity.startedByName || '-'}`,
                            url: entity.startedBy ? `/users/${entity.startedBy}` : undefined,
                            type: entity.acceptedAt ? 'raw' : 'hidden'
                        }, {
                            name: 'Slutfört',
                            value: !entity.completedAt
                                ? 'Nej' : `${formatDateTime(entity.completedAt)} av ${entity.completedByName || '-'}`,
                            url: entity.completedBy ? `/users/${entity.completedBy}` : undefined,
                            type: entity.startedAt ? 'raw' : 'hidden'
                        }, {
                            name: 'Arkiverat',
                            value: formatYesNo(entity.isArchived),
                            type: !entity.isDeleted ? 'raw' : 'hidden'
                        }, {
                            name: 'Borttaget',
                            value: formatYesNo(entity.isDeleted),
                            type: !entity.isArchived ? 'raw' : 'hidden'

                        }, {
                            name: 'Accepterat anbud',
                            value: formatYesNo(entity.acceptedOfferId !== null)
                        }, {
                            name: 'Antal anbud totalt',
                            value: entity.offerCount,
                            type: 'number'
                        }, {
                            name: 'Varav öppna anbud',
                            value: entity.pendingOfferCount,
                            type: (entity.acceptedOfferId !== null || entity.offerCount === 0) ? 'hidden' : 'number'
                        }]}
                    />
                    <Accordion onChange={() => setExpanded(!expanded)}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            sx={{ backgroundColor: '#f0f0f0' }}
                        >
                            <Typography
                                fontSize={14}
                                fontWeight={450}
                            >
                                {!expanded ? 'Flera uppgifter ↴' : 'Flera uppgifter:'}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ padding: 0 }}
                        >
                            <List
                                data={[{
                                    name: 'Publik beskrivning',
                                    value: entity.publicDescription || '-',
                                }, {
                                    name: 'Privat beskrivning',
                                    value: entity.privateDescription || '-',
                                }, {
                                    name: 'Anmäl intresse senast',
                                    value: formatDateTime(entity.expiresAt) || '-',
                                }, {
                                    name: 'Riktpris',
                                    value: entity.listPrice,
                                    type: 'currency'
                                }, {
                                    name: 'Skapat',
                                    value: !entity.createdAt
                                        ? '' : `${formatDateTime(entity.createdAt)} av ${entity.createdByName || '-'}`,
                                    url: entity.createdBy ? `/users/${entity.createdBy}` : undefined
                                }, {
                                    name: 'Senast uppdaterat',
                                    value: !entity.updatedAt
                                        ? '' : `${formatDateTime(entity.updatedAt)} av ${entity.updatedByName || '-'}`,
                                    url: entity.updatedBy ? `/users/${entity.updatedBy}` : undefined
                                }, {
                                    name: 'Utkast',
                                    value: !entity.draftedAt
                                        ? '' : `${formatDateTime(entity.draftedAt)} av ${entity.draftedByName || '-'}`,
                                    url: entity.draftedBy ? `/users/${entity.draftedBy}` : undefined

                                }, {
                                    name: 'Publicerat',
                                    value: !entity.publishedAt
                                        ? ''
                                        : `${formatDateTime(entity.publishedAt)} av ${entity.publishedByName || '-'}`,
                                    url: entity.publishedBy ? `/users/${entity.publishedBy}` : undefined
                                }]}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Block>
            </Grid>
        </Grid>
    );
}

export default function OrderDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<OrderType>
            fetchEntity={fetchOrderById(id)}
            selectEntity={selectOrderById(id)}
            selectIsFetching={selectFetchingOrderById(id)}
            Component={OrderDetails}
            name="uppdraget"
            initialFetch={!hasRendered}
        />
    );
}
