import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchOrdersByTenantId,
    selectFetchingOrdersByTenantId,
    selectOrdersByTenantId
} from '../../redux/actions/orders';
import {
    OrderType,
    OrderEditableType,
    OrderForListType,
    OrderStatusType
} from '../../types';
import { formatOrderFlag, formatOrderStatus } from '../../utils/parsing';
import { Table } from '../CommonComponents';
import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { canEditOrder } from '../../utils/order';

const columns = [{
    name: 'Nummer',
    key: 'id',
    canSearch: true
}, {
    name: 'Upphämtningsdatum',
    key: 'firstPickupDate',
    type: 'date' as 'date',
    canFilter: true
}, {
    name: 'Upphämtningsplats',
    key: 'pickupCity',
    canSearch: true
}, {
    name: 'Leveransplats',
    key: 'deliveryCity',
    canSearch: true
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg',
    canFilter: true
}, {
    name: 'Anbud\u00A0(öppna)',
    key: 'offerCount-pendingOfferCount',
    getValue: ({ offerCount, pendingOfferCount }: OrderType) => (`${offerCount} (${pendingOfferCount})`),
    canFilter: true
}, {
    name: 'Status',
    key: 'status',
    getValue: ({ status }: { status: OrderStatusType }) => formatOrderStatus(status),
    canSearch: true,
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}, {
    name: 'Flagga',
    key: 'isDeleted-isArchived',
    getValue: ({ isDeleted, isArchived }: OrderEditableType) => formatOrderFlag({ isDeleted, isArchived }),
    canFilter: true,
}, {
    name: 'Redigerbart',
    key: 'canEdit',
    type: 'bool' as 'bool',
    getValue: canEditOrder,
    canFilter: true,
}];

function TenantOrders(props: FetchPropsType<OrderForListType[]>) {
    const { entity, isFetching } = props;
    const navigate = useNavigate();
    const { id } = useParams() as unknown as { id: number };

    const onRowClick = React.useCallback((order: { id: number }) => {
        navigate(`/orders/${order.id}`);
    }, [navigate]);

    const onAddClick = React.useCallback(() => {
        navigate(`/tenants/${id}/orders/create`);
    }, [navigate, id]);

    return (
        <Table
            name="tenantOrders"
            columns={columns}
            data={entity}
            onRowClick={onRowClick}
            onAddClick={{ action: onAddClick, label: 'Skapa nytt' }}
            emptyText={isFetching ? 'Hämtar uppdrag...' : 'Det finns inga uppdrag.'}
            showPagination
            defaultOrderBy="createdAt"
            defaultOrderDirection="asc"
        />
    );
}

export default function TenantDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<OrderForListType[]>
            fetchEntity={fetchOrdersByTenantId(id)}
            selectEntity={selectOrdersByTenantId(id)}
            selectIsFetching={selectFetchingOrdersByTenantId(id)}
            Component={TenantOrders}
            name="uppdrag"
            initialFetch={!hasRendered}
        />
    );
}
