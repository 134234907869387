import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PublishedWithChanges as PublishedWithChangesIcon } from '@mui/icons-material';
import { endOfTomorrow } from 'date-fns';

import {
    useChangeable,
    FullForm
} from '../CommonComponents/Form';

import {
    createOrder,
    getOrder,
    updateOrder,
    deleteOrder
} from '../../api-admin/orders';

import toast from '../../utils/toast';
import OrderFormFields from './OrderFormComponents/OrderFormFields';
import getDrivingDistance from '../../utils/getDrivingDistance';
import { getFakeCoordinate } from '../../utils/geometry';
import { orderStatus } from '../../utils/status';
import { NotFound } from '../CommonComponents';
import Loading from '../CommonComponents/Fetch/Loading';
import { getTenant } from '../../api-admin/tenants';

async function addDistancesAndFakeLocationCoordinates(entity: { [key: string]: any }) {
    let d;
    try {
        d = await getDrivingDistance(entity);
    } catch {
        d = { drivingDistance: null, drivingDuration: null };
    }
    return ({
        ...entity,
        fakePickupLat: getFakeCoordinate(entity.pickupLat),
        fakePickupLng: getFakeCoordinate(entity.pickupLng),
        fakeDeliveryLat: getFakeCoordinate(entity.deliveryLat),
        fakeDeliveryLng: getFakeCoordinate(entity.deliveryLng),
        drivingDistance: d.drivingDistance,
        drivingDuration: d.drivingDuration
    });
}

export default function OrderForm() {
    const { id, tenantId } = useParams();
    const navigate = useNavigate();
    const hasFetchedRef = React.useRef(false);

    const defaultOrder = React.useMemo(() => ({
        firstPickupDate: endOfTomorrow().toISOString().slice(0, 10),
        lastPickupDate: null,
        firstDeliveryDate: endOfTomorrow().toISOString().slice(0, 10),
        lastDeliveryDate: null,
        firstPickupTime: null,
        lastPickupTime: null,
        firstDeliveryTime: null,
        lastDeliveryTime: null,

        pickupLat: null,
        pickupLng: null,
        pickupAttention: '',
        pickupStreet: '',
        pickupZip: '',
        pickupCity: '',
        pickupCountry: '',
        deliveryLat: null,
        deliveryLng: null,
        deliveryAttention: '',
        deliveryStreet: '',
        deliveryZip: '',
        deliveryCity: '',
        deliveryCountry: '',
        fakePickupLat: null,
        fakePickupLng: null,
        fakeDeliveryLat: null,
        fakeDeliveryLng: null,

        grossWeight: null,
        chargeableWeight: null,
        volume: null,
        loadingMeters: null,
        palletPlaces: null,
        pallets: null,

        isPublic: false,
        showTenantName: true,
        groups: [],
        listPrice: null,
        expiresAt: null,
        publicDescription: '',
        privateDescription: ''
    }), []);

    const [entity, onChange] = useChangeable(defaultOrder);

    const getDataForEditingOrder = React.useCallback(async () => {
        try {
            if (!id) {
                throw new Error('Could not retrieve order, missing id');
            }
            const order = await getOrder(id);
            if (!order) {
                throw new Error(`Could not retrieve order with id: ${id}`);
            }
            hasFetchedRef.current = true;
            onChange(order);
        } catch (e: any) {
            toast(e, 'Kunde inte hämta uppdraget, försök igen', false);
            navigate(-1);
        }
    }, [id, navigate, onChange]);

    const getDataForCreatingOrder = React.useCallback(async () => {
        try {
            if (!tenantId) {
                throw new Error('Could not retrieve tenant, missing tenant id');
            }
            const { name: tenantName } = await getTenant(tenantId);
            if (!tenantName) {
                throw new Error(`Could not retrieve name for tenant with id: ${tenantId}`);
            }
            hasFetchedRef.current = true;
            onChange({
                tenantId,
                tenantName
            });
        } catch (e: any) {
            toast(e, 'Kunde inte hämta nödvändiga uppgifter, försök igen', false);
            navigate(-1);
        }
    }, [tenantId, navigate, onChange]);

    React.useEffect(() => {
        if (id && !tenantId && !hasFetchedRef.current) {
            getDataForEditingOrder();
        } else if (tenantId && !id && !hasFetchedRef.current) {
            getDataForCreatingOrder();
        }
    }, [id, tenantId, getDataForEditingOrder, getDataForCreatingOrder]);

    const onSubmit = React.useCallback(async () => {
        try {
            const order = await addDistancesAndFakeLocationCoordinates(entity);
            if (id) {
                await updateOrder(id, order);
                toast('Uppdraget uppdaterat', undefined, true);
                navigate(-1);
            } else {
                const createdOrder = await createOrder({ ...order, publishDirectly: true });
                toast('Uppdraget publicerat', undefined, true);
                navigate(`/orders/${createdOrder.id}`, { replace: true });
            }
        } catch (e: any) {
            toast(e, `Kunde inte ${id ? 'uppdatera' : 'publicera'} uppdraget, försök igen`, false);
        }
    }, [entity, id, navigate]);

    const onDelete = React.useCallback(async () => {
        try {
            const order = await addDistancesAndFakeLocationCoordinates(entity);
            if (id) {
                await deleteOrder(id, order);
                toast('Uppdraget borttaget', undefined, true);
                navigate('/orders', { replace: true });
            }
        } catch (e: any) {
            toast(e, 'Kunde inte ta bort uppdraget, försök igen', false);
        }
    }, [entity, id, navigate]);

    if (id) {
        if (!hasFetchedRef.current) {
            return <Loading name="uppdraget" />;
        }
        if (entity.isArchived || ![orderStatus.DRAFTED, orderStatus.PUBLISHED].some((s) => s === entity.status)) {
            return <NotFound />;
        }
    }

    return (
        <FullForm
            title={`${entity.id ? 'Redigera' : 'Skapa'} uppdrag`}
            entity={entity}
            onSubmit={onSubmit}
            submitText={entity.id ? 'Spara' : 'Publicera'}
            onDelete={entity.id ? onDelete : undefined}
            submitStartIcon={entity.id ? undefined : <PublishedWithChangesIcon />}
            showBottomSave
        >
            <OrderFormFields
                entity={entity}
                onChange={onChange}
            />
        </FullForm>
    );
}
