import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Table } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchAllGroups,
    selectAllGroups,
    selectFetchingAllGroups
} from '../../redux/actions/groups';

const columns = [{
    name: 'Namn',
    key: 'name',
    canSearch: true
}, {
    name: 'Administratör',
    key: 'ownerTenantName',
    canSearch: true
}, {
    name: 'Publik',
    key: 'isPublic',
    type: 'bool' as 'bool',
    canFilter: true
}, {
    name: 'Antal inbjudningar',
    key: 'invitationCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Antal medlemmar',
    key: 'memberCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Antal uppdrag',
    key: 'orderCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Groups() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const groups = useSelector(selectAllGroups());
    const isFetching = useSelector(selectFetchingAllGroups());

    React.useEffect(() => {
        dispatch(fetchAllGroups());
    }, [dispatch]);

    const onRowClick = React.useCallback((group: { id: number }) => {
        navigate(`/groups/${group.id}`);
    }, [navigate]);

    return (
        <Container>
            <Header
                title="Grupper"
                addButton={{ url: '/groups/create' }}
            />

            <Paper padding={0}>
                <Table
                    name="groups"
                    columns={columns}
                    data={groups}
                    onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar grupper...' : 'Det finns inga grupper.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                />
            </Paper>
        </Container>
    );
}
