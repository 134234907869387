import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { Block, Header, List } from '../CommonComponents';

import { FetchPropsType } from '../CommonComponents/Fetch/FetchPropsType';
import { WatchlistType } from '../../types';
import { fetchWatchlistById, selectFetchingWatchlistById, selectWatchlistById } from '../../redux/actions/watchlists';
import FetchRedux from '../CommonComponents/Fetch/FetchRedux';
import { Container, Paper } from '../StyledComponents';
import {
    formatDateTime,
    formatDelivery,
    formatPickup,
    formatRange,
    formatType
} from '../../utils/parsing';
import FilterMap from '../CommonComponents/GoogleMap/FilterMap';

function WatchlistDetails(props: FetchPropsType<WatchlistType>) {
    const { entity } = props;

    return (
        <Container>
            <Header
                title={`Bevakning: ${entity.name}`}
                subtitle={`Användare: ${entity.userName} på ${entity.tenantName}`}
                editUrl={`/watchlists/${entity.id}/edit`}
            />
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} lg={6}>
                    <Block
                        title="Grunduppgifter"
                        variant="outlined"
                    >
                        <List
                            data={[{
                                name: 'Typ',
                                value: formatType(entity)
                            }, {
                                name: 'Upphämtningsområde',
                                value: formatPickup(entity)
                            }, {
                                name: 'Leveransområde',
                                value: formatDelivery(entity)
                            }, {
                                name: 'Direktnotiser',
                                value: entity.instantNotifications,
                                type: 'bool'
                            }, {
                                name: 'Dagliga notiser',
                                value: entity.dailyNotifications,
                                type: 'bool'
                            }, {
                                name: 'Aktiva e-postnotiser',
                                value: entity.activeEmailNotifications,
                                type: 'bool'
                            }, {
                                name: 'Totalvikt [kg]',
                                value: formatRange(entity.minGrossWeight, entity.maxGrossWeight, 'kg')
                            }, {
                                name: 'FDR-vikt [kg]',
                                value: formatRange(entity.minChargeableWeight, entity.maxChargeableWeight, 'kg')
                            }, {
                                name: 'Volym [m³]',
                                value: formatRange(entity.minVolume, entity.maxVolume, 'm³')
                            }, {
                                name: 'Flakmeter',
                                value: formatRange(entity.minLoadingMeters, entity.maxLoadingMeters, 'flm')
                            }, {
                                name: 'Pallplatser',
                                value: formatRange(entity.minPalletPlaces, entity.maxPalletPlaces, 'st')
                            }, {
                                name: 'Pallar',
                                value: formatRange(entity.minPallets, entity.maxPallets, 'st')
                            }, {
                                name: 'Endast uppdrag i grupp',
                                value: entity.groupName,
                                url: entity.groupId ? `/groups/${entity.groupId}` : undefined
                            }, {
                                name: 'Endast uppdrag med riktpris',
                                value: entity.hasListPrice,
                                type: 'bool'
                            }, {
                                name: 'Endast uppdrag som tar intresseanmälningar',
                                value: entity.takesOffer,
                                type: 'bool'
                            }, {
                                name: 'Skapad',
                                value: !entity.createdAt
                                    ? '' : `${formatDateTime(entity.createdAt)} av ${entity.createdByName || '-'}`,
                                url: entity.createdBy ? `/users/${entity.createdBy}` : undefined
                            }, {
                                name: 'Uppdaterad',
                                value: !entity.updatedAt ? '' : `${formatDateTime(entity.updatedAt)} av ${entity.updatedByName || '-'}`,
                                url: entity.updatedBy ? `/users/${entity.updatedBy}` : undefined
                            }]}
                        />
                    </Block>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Paper padding={0} sx={{ height: '100%' }}>
                        <FilterMap
                            pickupLat={entity.pickupLocationLat}
                            pickupLng={entity.pickupLocationLng}
                            pickupRadius={entity.pickupLocationRadius}
                            deliveryLat={entity.deliveryLocationLat}
                            deliveryLng={entity.deliveryLocationLng}
                            deliveryRadius={entity.deliveryLocationRadius}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default function WatchlistDetailsFetched({ hasRendered }: { hasRendered?: boolean }) {
    const { id } = useParams() as unknown as { id: number };

    return (
        <FetchRedux<WatchlistType>
            fetchEntity={fetchWatchlistById(id)}
            selectEntity={selectWatchlistById(id)}
            selectIsFetching={selectFetchingWatchlistById(id)}
            Component={WatchlistDetails}
            name="bevakningen"
            initialFetch={!hasRendered}
        />
    );
}
