import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Link, Typography } from '@mui/material';
import {
    formatBool,
    formatDate,
    formatDateTime,
    formatNumber
} from '../../utils/parsing';

type RowType = {
    name: string,
    value: string | number | boolean | JSX.Element | null,
    valuePadding?: number,
    type?: 'email'
    | 'phone'
    | 'website'
    | 'bool'
    | 'y/n'
    | 'date'
    | 'datetime'
    | 'currency'
    // | 'pieces'
    | 'km'
    | 'kg'
    | 'number'
    | 'raw'
    | 'hidden',
    url?: string
}

type PropsType = {
    data: RowType[],
    size?: 'small' | 'medium'
};

function getValue(row: RowType) {
    switch (row.type) {
        case 'email':
            return <Link href={`mailto:${row.value}`}>{row.value}</Link>;

        case 'phone':
            return <Link href={`tel:${row.value}`}>{row.value}</Link>;

        case 'website':
            return <Link href={`${row.value}`} target="_blank">{row.value}</Link>;

        case 'bool':
            return formatBool(row.value);

        case 'y/n':
            return row.value ? 'Ja' : 'Nej';

        case 'date':
            return formatDate(row.value);

        case 'datetime':
            return formatDateTime(row.value);

        case 'currency':
            return formatNumber(row.value, 'SEK');

        // case 'pieces':
        //     return formatNumber(row.value, 'st');

        case 'km':
            return formatNumber(row.value, 'km');

        case 'kg':
            return formatNumber(row.value, 'kg');

        case 'number':
            return formatNumber(row.value, 'st');

        default:
            return row.value;
    }
}

const BasicList: React.FC<PropsType> = (props) => {
    const { data, size } = props;
    const navigate = useNavigate();

    return (
        <TableContainer>
            <Table size={size} aria-label="basic list">
                <TableBody>
                    {data.filter((row) => (row.type !== 'hidden'))
                        .map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    ':hover': {
                                        cursor: row.url ? 'pointer' : 'inherit',
                                        background: '#EEE'
                                    }
                                }}
                                onClick={
                                    row.url
                                        ? () => navigate(row.url as string)
                                        : undefined
                                }
                            >
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    <Typography variant="subtitle2">
                                        {row.name}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top', p: row.valuePadding }}>
                                    {row.type === 'raw' ? row.value : (
                                        <Typography variant="body2">
                                            {getValue(row)}
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BasicList;
